// @ts-nocheck
import React from "react";
import "./index.scss";
import { ProfileOutlined, AppstoreOutlined, UserOutlined,PoweroffOutlined, SyncOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { Empty, Button ,Avatar,List,Modal,message,Spin} from 'antd';
import {dateFormat, getWeekDay, randNum} from "../../common/util";
import http from "../../common/util/request";
import emptyImag from "../../common/img/empty.png"
import { deviceDetection, isSupportWebRTC } from "../../common/serve/rtcServe";
import {rtcConfig} from "../../../config/rtcConfig";
import {createClient, imClient} from "../../common/serve/imServe";
import paramServer from "../../common/js/paramServer";
import Cookies from 'js-cookie'

// import { Layout } from 'antd';
// const { Header, Footer, Sider, Content } = Layout;
class ClassList extends React.Component {
  constructor(props) {
    super(props);
    let user ={};
    let userStr = Cookies.get('userInfo');
    console.log('userStr',userStr);
    if (userStr !== undefined && userStr !== ''){
      user = JSON.parse(userStr);
    }
    console.log(user);
    this.state = {
      isListEmpty:true,
      loading:true,
      liveRoomLoading:false,
      liveList:[],
      selectLiveItem:null,
      ...user
      // ...store.getState().user
    }
  };

  gotoManager() {
    // window.location.href = 'http://manager.weshilive.com';
    console.log('gotoManager');
    let win = window.open('http://manager.weshilive.com', '_blank');
    win.focus();
  };

  gotoManagerLive() {
    console.log('gotoManagerLive');
    // this.openNewTab('http://manager.weshilive.com/#/course-live-live');
    // window.location.href = 'http://manager.weshilive.com/#/course-live-live';
    let win = window.open('http://manager.weshilive.com/#/course-live-live', '_blank');
    win.focus();
  };

  quitLive() {
    // window.location.href = 'http://www.weshilive.com';
    let win = window.open('http://www.weshilive.com', '_blank');
    win.focus();
  };


  //去直播房间
  gotoLiveRoom(item) {
    console.log('course',item);
    this.setState({
      selectLiveItem:item
    });
    //先检测是否支持URTC
    let support = isSupportWebRTC();
    console.log('support',support);
    if (!support) {
      message.warning('您的浏览器不支持WebRTC音视频服务，请更换使用Chrome、Firefox、Safari');
      return
    }
    //检查麦克风和摄像头
    this.testDevice()
  };
  testDevice = () => {
    let _that = this;
    deviceDetection(
        {
          audio: true, // 必填，指定是否检测麦克风设备
          video: true
        },
        Result => {
          if (Result.audio && Result.video) {
            // 麦克风和摄像头都可有和，发布或预览时可启用麦克风和摄像头
            _that.joinRoom();
          }else {
            // 麦克风和摄像头都不可用
            let msg = "麦克风和摄像头不可用，请检查使用权限!";
            if (Result.audio) {
              // 麦克风可用，发布或预览时能启用麦克风
              msg = '摄像头不可用，请检查使用权限!';
            } else if (Result.video) {
              // 摄像头可用，发布或预览时能启用摄像头
              msg = '麦克风不可用，请检查使用权限!';
            }
            Modal.confirm({
              title: '是否进入直播间',
              icon: <ExclamationCircleOutlined />,
              content: <span style={{color:'#ff4500'}}>{msg}</span>,
              cancelText: '取消',
              okText: '进入直播间',
              onOk:()=>{
                _that.joinRoom();
              }
            });
          }
        }
    );
  };
  joinRoom = () => {
    let appData = {
      appId: rtcConfig.appId,
      userId:this.state.uid,
      mediaType: "1", //桌面和摄像头采集类型
      appkey: rtcConfig.appkey,
    };

    createClient(appData.appId);
    // const { role_type, roomId, name, room_type, businessType }
    const role_type = "2";
    const roomId = this.state.selectLiveItem.liveRoomId;
    const courseId = this.state.selectLiveItem.id;
    const name = this.state.name;
    const room_type = 1;
    const businessType = 'class';

    this.setState({
      liveRoomLoading: true,
    });

    let param = {
      room_type: room_type - 0,
      role_type: room_type === 0 ? 2 : role_type - 0,
      courseId,
      roomId,
      name,
      businessType,
      ...appData,
    };
    console.log(param);
    this.joinIM(param);
  };

  updateLiveStatus() {
    let params = {
      courseId: this.state.selectLiveItem.id,
      status:1
    };
    http.post('/weikecourse/live/updateLiveStatus', params).then(res=>{
    }).catch(reason => {
      console.log(reason)
    });
  };

  //加入im房间
  joinIM(param) {
    let type = "admin";
    let businessType = param.businessType;
    if (param.room_type === 1) {
      //大班课
      type = param.role_type === 2 ? "admin" : "default";
    }
    try {
      imClient.joinRoom(
          param.roomId,
          param.userId,
          type,
          param.name,
          (data) => {
            console.log("CallTeamList", data);
            imClient.createWhite((whiteData) => {
              console.log("whiteData", whiteData);
              this.updateLiveStatus();
              let { uuid, token } = whiteData;
              paramServer.setParam(
                  Object.assign(
                      {
                        defaultList: data.defaultUsers,
                        adminList: data.adminUsers,
                        roomInfo: data.roomInfo,
                        Token: token,
                        Uuid: uuid,
                      },
                      param
                  )
              );
              this.setState({
                liveRoomLoading: false,
              });
              // const { businessType } = this.state;
              if (type === "admin") {
                if (businessType === "class") {
                  this.props.history.push({ pathname: `/class` });
                } else {
                  this.props.history.push({ pathname: `/liveroom` });
                }
              }
            });
          },
          (error) => {
            message.error("进入直播间失败，请稍后重试");
            this.setState({
              liveRoomLoading: false,
            });
          }
      );
    } catch (err) {
      message.error("进入直播间失败，请稍后重试");
      // this.props.history.push({ pathname: `/student` });
    }
  }

  //转换直播时长
  transLiveLong(long) {
    let totalMin = long*60;
    let h = parseInt(long);
    let min = totalMin % 60;
    let hStr = '';
    if (h>0) {
      hStr = h +'小时';
    }

    return hStr +  min + '分钟'
  };

  refreshList() {
    this.setState({
      loading:true
    });
    this.getLiveList();
  };

  getLiveList() {
    http.get('/weikecourse/live/getLiveList',{
      params:{
        uid:this.state.uid
      }}).then(res=>{
      console.log(res);
      if (res.data.code === 0 && res.data.data !== null && res.data.data.length>0) {
        this.setState({
          loading:false,
          isListEmpty:false,
          liveList:res.data.data
        })
      } else {
        this.setState({
          loading:false,
          isListEmpty:true,
          liveList:[]
        })
      }
    }).catch(reason => {
      console.error(reason)
    })
  };

  componentDidMount() {
    //获取待直播列表
    this.getLiveList();
  }

  render() {
    return (
      <div className="bodyBox">
        <div className="boxLeft">
          <div className="boxLeftTop">
            <Avatar size={50} icon={<UserOutlined />} src={this.state.headUrl} />
            <span className="userName">{this.state.name}</span>
            <span className="userTime">{dateFormat(new Date(),'yyyy-MM-dd')}</span>
            <span className="userTime">{getWeekDay(new Date())}</span>
          </div>
          <div className="boxLefMenu">
            <div className="menu">
              <span><ProfileOutlined /> </span>
              <span>直播课程</span>
            </div>
            <div className="menu1">
              <div onClick={this.gotoManager}>
                <span><AppstoreOutlined /> </span>
                <span>管理后台</span>
              </div>
              <div onClick={this.quitLive} style={{marginTop:'15px'}}>
                <span><PoweroffOutlined /> </span>
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </div>
        <div className="boxRight">
            <div className="boxTitle">
              <span style={{ paddingLeft: "20px" }}>我的课程</span>
              <span onClick={() => this.refreshList()} style={{ paddingRight: "30px", cursor: "pointer"}}><SyncOutlined style={{ paddingRight: "5px" }}/>刷新列表</span>
            </div>
            <div className={`${this.state.isListEmpty?'emptyContent':'hide'}`} >
              <Empty
                  image={emptyImag}
                  description={<span>暂无课程，先去开课吧</span>}
              >
                <Button onClick={this.gotoManagerLive} danger>立即开课</Button>
              </Empty>
            </div>
            <div className={`${this.state.isListEmpty?'hide':'liveList'} `}>
              <List
                  itemLayout="horizontal"
                  split="true"
                  loading={this.state.loading}
                  dataSource={this.state.liveList}
                  renderItem={item => (
                      <List.Item
                          extra={
                            <Button onClick={()=>this.gotoLiveRoom(item)} type="primary" style={{marginRight: "40px"}} danger>
                              去直播
                            </Button>
                          }
                      >
                        <List.Item.Meta
                            avatar={<img width={100} style={{marginLeft: "40px"}} src= {item.coursePic} />}
                            title={<p>{item.courseName}</p>}
                            description={
                              <div>
                                <div style={{color:'#ff4500'}}>开播时间：{item.courseBeginTime}</div>
                                <div style={{color:'#ff4500'}}>预计时长：{this.transLiveLong(item.courseLong)}</div>
                                <div style={{color:'#ff4500'}}>直播状态：{item.liveStatus}</div>
                              </div>
                            }
                        />
                      </List.Item>
                  )}
              />,
            </div>
          </div>
        <div  className={`${this.state.liveRoomLoading?'loading':'hide'}`}>
          <Spin size="large" tip="正在进入直播间，请稍后~" />
        </div>
      </div>
    );
  }
}

export default ClassList;
