import React from "react";
import Cookies from 'js-cookie'
import { withRouter } from 'react-router-dom';
import http from "../../common/util/request";
import { Spin } from 'antd';
import './index.scss'
import {getUrlParam} from "../../common/util";
import store from "../../store";

class LoadLogin extends React.Component {
    componentDidMount() {
        let search = this.props.history.location.search;
        let token = getUrlParam(search,'token') || Cookies.get('token');
        if (token) {
            //登录页面拼接token  测试使用
            this.getUserInfo(token)
        } else {
            let code = Cookies.get('code');
            console.log('code',code);
            this.getToken(code);
        }
        // console.log('LoadLogin componentDidMount search',search);
    };

    getUserInfo(token) {
        http.get('/sys/user/info').then(res=>{
            console.log('userinfo res', res);
            let userinfo = {
                headUrl:res.data.data.headUrl,
                name:res.data.data.realName,
                uid:res.data.data.id
            };
            Cookies.set('token', token, { expires: 7 });
            Cookies.set('userInfo', userinfo, { expires: 7 });
            this.props.history.push('/');
        }).catch(reason => {
            console.log(reason)
        });
    };

    getToken(code) {
        http.post('/wx/login', { code: code }).then(res=>{
            console.log('code res', res);
            let userinfo = {
                headUrl:res.data.data.headUrl,
                name:res.data.data.realName,
                uid:res.data.data.id
            };
            Cookies.set('token', res.data.data.token, { expires: 7 });
            Cookies.set('userInfo', userinfo, { expires: 7 });
            this.props.history.push('/');
        }).catch(reason => {
            console.log(reason)
        });

    };

    saveStates(user) {
        const action = {
            type:'SAVE_USER',
            value:user
        };
        store.dispatch(action)
    }

    render() {
        return (
            <div className="loading">
                <Spin size="large" tip="正在登录中，请稍后~"/>
            </div>
        );
    }
}


export default withRouter(LoadLogin);
