// @ts-nocheck
import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { Message } from "@ucloud-fe/react-components";
import paramServer from "../common/js/paramServer";
import {appendParams,getUrlParam} from '../common/util';
import store from '../store'
import Cookies from 'js-cookie'
import http from "../common/util/request";

// @ts-ignore
import { rtcConfig } from "../../config/rtcConfig";
import { ossConfigObj } from "../../config/writeConfig";




export class FrontendAuth extends React.Component {
    render() {
    const { location, config } = this.props;
    const { pathname,search } = location;
    // console.log(location);
    // console.error(pathname);
    let isLogin = false;
    // if (paramServer.getParam() && paramServer.getParam().roomId) {
    //   isLogin = true;
    // }
    let token = Cookies.get('token');
    if (token) {
        isLogin = true;
    }

    // 如果该路由不用进行权限校验，登录状态下登陆页除外
    // 因为登陆后，无法跳转到登陆页
    // 这部分代码，是为了在非登陆状态下，访问不需要权限校验的路由
    const targetRouterConfig = config.find((v) => v.path === pathname);
    if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
      console.log(targetRouterConfig);
      const { component } = targetRouterConfig;
      return <Route exact path={pathname} component={component} />;
    }
    if (isLogin) {
        if (!rtcConfig.appId || !rtcConfig.appkey) {
            Message.error("请补全URTC配置");
        } else if (
            !ossConfigObj.accessKeyId ||
            !ossConfigObj.accessKeySecret ||
            !ossConfigObj.bucket ||
            !ossConfigObj.region
        ) {
            Message.error("请补全白板配置");
        }
      // 如果是登陆状态，想要跳转到登陆，重定向到主页
      if (pathname === "/login") {
        return <Redirect to="/" />;
      } else {
        // 如果路由合法，就跳转到相应的路由
        if (targetRouterConfig) {
          return (
            <Route path={pathname} component={targetRouterConfig.component} />
          );
        } else {
          // 如果路由不合法，重定向到 404 页面
          // return <Redirect to='/404' />
          return <Redirect to="/" />;
        }
      }
    } else {
        let code = getUrlParam(search,'code');
      // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
      if (targetRouterConfig && targetRouterConfig.auth) {
        // return <Redirect to="/login" />;
          if (code) {
              Cookies.set('code',code);
              return <Redirect to="/loading" />;
              // return <Redirect to="/" />;
          } else {
              const  redirectUri = appendParams('http://manager.weshilive.com/wxCode.html', {
                  'appid':'wxfe7c8827c49135b0',
                  'redirect_uri': encodeURIComponent(window.location.href),
                  'response_type': 'code',
                  'scope': 'snsapi_login',
                  'state': encodeURIComponent('teacher-web'),
              });
              window.location.href = redirectUri;
              return ;
          }
      } else {
        // 非登陆状态下，路由不合法时，重定向至 404
        return <Redirect to="/" />;
      }
    }
  }
}
