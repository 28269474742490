const defaultState = {
    user:null
};
export default (state = defaultState,action)=>{
    if(action.type === 'SAVE_USER'){
        // why copy old state -> newState ? reducer 可以接收state 不能修改state！！！
        const newState = JSON.parse(JSON.stringify(state)); // 深度拷贝
        newState.user = action.value;
        return newState;
    }
    return state;
}
